import React, { useContext } from 'react';
import { appContext } from 'providers/appProvider';
import { Layout, Seo, FaqsSet } from 'components';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ScrollableAnchor, { goToAnchor } from 'react-scrollable-anchor';

import ContentBlock from 'components/Content/ContentBlock';

// import page style
import './faqs.scss';
import FaqsDataNutritiondirectPatient from 'shared/FaqsDataPatient';

import NDPatientFAQsHeroMobile from '../../assets/images/ND-patient-FAQs-mobile.png';

const faqsPage = () => {
  const { appConfigs } = useContext(appContext);

  const indication = appConfigs?.siteOptions?.INDICATIONS?.nutritionPatient;
  // const metaData = appConfigs?.metaData?.nutritionPatient;

  const brandNames = {
    NASCOBAL: appConfigs?.metaData?.brandNames?.nascobal,
    BARIACTIV: appConfigs?.metaData?.brandNames?.bariactiv,
    NUTRITION: appConfigs?.metaData?.brandNames?.nutrition,
  };

  const pageTitle = 'NUTRITION DIRECT™ | FAQs';
  const pageDescription =
    'See frequently asked questions about Nutrition Direct™ with BariActiv® supplements';

  const renderNutritionDirect = () => {
    return (
      <ContentBlock
        id="why-nutrition-block"
        className="no-padding-top margin-offset"
      >
        <Grid
          fluid
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
          }}
        >
          <Row>
            <Col xs={12}>
              <div id="hero" className="hide-in-small">
                <h2>
                  FREQUENTLY ASKED <br className="hide-only-mobile" />
                  QUESTIONS
                </h2>
              </div>
              <div id="hero-mobile" className="show-in-small">
                <img src={NDPatientFAQsHeroMobile} />
              </div>
            </Col>
          </Row>

          <h2 className="strong txt-have-questions">
            You may have questions about{' '}
            <span
              className="brandname-nutrition"
              dangerouslySetInnerHTML={{
                __html: brandNames.NUTRITION,
              }}
            />{' '}
            with{' '}
            <span
              className="brandname-nutrition"
              dangerouslySetInnerHTML={{
                __html: brandNames.BARIACTIV,
              }}
            />{' '}
            supplements. Here are some of the most common ones and their answers
          </h2>

          <Row>
            <Col xs={12}>
              <FaqsSet
                faqData={
                  FaqsDataNutritiondirectPatient.nutritionDirectSupplementData
                }
                className=""
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <p className="footnote">
                ASMBS: American Society for Metabolic and Bariatric Surgery.
              </p>
              <p className="footnote asterik">
                ASMBS Integrated Health Nutritional Guidelines for the Surgical
                Weight Loss Patient — 2016 Update: Micronutrients.
              </p>
              <p className="footnote dagger">
                Patients may redeem this offer ONLY when accompanied by a valid
                prescription. Offer is valid up to a maximum benefit of $150.
                Offer is not valid for patients whose prescriptions are
                reimbursed in whole or in part under Medicaid, Medicare,
                Medigap, VA, DoD, TRICARE, or any other federal or state
                programs (such as medical assistance programs) or where
                otherwise prohibited by law. Offer is not valid in VT or where
                prohibited in whole or in part. This offer may be amended or
                ended at any time without notice.
              </p>
            </Col>
          </Row>
        </Grid>
      </ContentBlock>
    );
  };
  const renderPage = () => {
    return (
      <div id="nutrition-patient-FAQs">
        {renderNutritionDirect()}
        <ScrollableAnchor id="isi_anchor">
          <div />
        </ScrollableAnchor>
      </div>
    );
  };

  return (
    <Layout indication={indication} className="nutrition-patient-faqs-page">
      <Seo pageTitle={pageTitle} pageDescription={pageDescription} />
      <div className="body-content-container">
        <div className="body-content gutter-all">{renderPage()}</div>
      </div>
    </Layout>
  );
};

export default faqsPage;
